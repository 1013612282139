const pageData = {
    "/": {
      backgroundImage: "/images/bbs-hero-3.webp",
      heading: "Welcome to the",
      pageDesc: "15 - 17 September 2025",
    },
    "/registration/": {
      heading: "Registration",
      backgroundImage: "/images/bbs-hero-2.webp",
    },
    "/destinations/": {
      heading: "Destinations",
      backgroundImage: "/images/bbs-hero-4.webp",
    },
    "/latest-news/": {
      heading: "Latest News",
    },
    "/sponsors-and-exhibitors/": {
      heading: "Sponsors & Exhibitors",
    },
    "/demo/": {
      heading: "Download Form",
    },
    "/contact/": {
      heading: "Contact Us",
      backgroundImage: "/images/bbs-hero-5.webp",
    },
    "/host/": {
      heading: "Host",
      backgroundImage: "/images/bbs-hero-1.webp",
    },
    "/venue/": {
      heading: "Venue",
      backgroundImage: "/images/bbs-hero-1.webp",
    },
    "/mackay/": {
      heading: "Mackay",
      backgroundImage: "/images/bbs-hero-4.webp",
    },
    "/privacy-policy/": {
      heading: "Privacy Policy",
      backgroundImage: "/images/bbs-hero-3.webp",
    },
    "/call-for-abstracts/": {
      heading: "Call for Abstracts",
      backgroundImage: "/images/bbs-hero-2.webp",
    },
    "/opportunities/": {
      heading: "Sponsorship Opportunities",
      backgroundImage: "/images/bbs-hero-1.webp",
    },
    "/accommodation/": {
      heading: "Accommodation",
      backgroundImage: "/images/bbs-hero-4.webp",
    },
    "/program-overview/": {
      heading: "Program Overview",
    },
  };
  
  export const getPageData = (path) => {
    const defaultData = {
      backgroundImage: "/images/bbs-hero-2.webp",
    };
  
    const data = pageData[path] || {};
  
    return {
      ...defaultData,
      ...data,
    };
  };
  