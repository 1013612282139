import React from 'react'
import { KeyDates } from '@/components/KeyDates'

export default function Sidebar() {
  return (
    <>
      <div className="p-4 bg-white/40 rounded-2xl sticky top-4">
          <h3 className="mt-0 text-c1">Key Dates</h3>
          <KeyDates />
      </div>
    </>
  )
}
