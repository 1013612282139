import React from "react";
import { useLocation } from "@reach/router";
import { getPageData } from '@/data/pageData';
import { CodeIcon, BoltIcon, LayersIcon, SettingsIcon } from "@/components/icons/icons";
import FeatureCard from "@/components/cards/FeatureCard";
import FWNavBar from "@/components/navbar/FullWidthNavBar";
import InLineNavBar from "@/components/navbar/InLineNavBar";
import config from '@/utils/config';

const Header = () => {
  const location = useLocation();
  const { backgroundImage } = getPageData(location.pathname);

  let featureSection;

  if (location.pathname === "/") {
    featureSection = (
      <section className="py-12 md:py-20 relative z-10">
        <div className="container mx-auto grid grid-cols-1 gap-6 px-6 md:grid-cols-2 lg:grid-cols-4">
          <FeatureCard
            Icon={CodeIcon}
            title="Rapid Development"
            description="Streamline your development process with our powerful tools and frameworks."
          />
          <FeatureCard
            Icon={BoltIcon}
            title="Blazing Fast"
            description="Our optimized infrastructure ensures lightning-fast performance for your apps."
          />
          <FeatureCard
            Icon={LayersIcon}
            title="Scalable Solutions"
            description="Easily scale your applications to handle growing traffic and user demands."
          />
          <FeatureCard
            Icon={SettingsIcon}
            title="Dedicated Support"
            description="Our expert team is here to help you every step of the way, 24/7."
          />
        </div>
      </section>
    );
  }

  return (
    <>
      <header
        className="relative text-white"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="absolute inset-0 bg-gray-900 opacity-70"></div>
        {config.inlineNavbar ? <InLineNavBar /> : <FWNavBar />}

        

        {config.headerFeatureBoxes ? featureSection : null }
      </header>
    </>
  );
}

export default Header;
