export const menuItems = {

  // header menu items
  header: [
    {
      label: "Home",
      to: "/",
      dropdown: false,
    },
    {
      label: "About",
      to: "",
      dropdown: true,
      items: [
        { label: "Host", to: "/host" },
        // { label: "Committee", to: "/committee" },
        // { label: "Latest News", to: "/latest-news" },
        { label: "Privacy Policy", to: "/privacy-policy" },
      ],
    },
    {
      label: "Registration",
      to: "",
      dropdown: true,
      items: [
        { label: "Registration", to: "/registration" },
        { label: "Accommodation", to: "/accommodation" },
      ],
    },
    {
      label: "Destination",
      to: "",
      dropdown: true,
      items: [
        { label: "Mackay", to: "/mackay" },
        { label: "Venue", to: "/venue/#mackay" },
      ],
    },
    {
      label: "Program",
      to: "",
      dropdown: true,
      items: [
        { label: "Program Overview", to: "/program-overview" },
        { label: "Call for Abstracts", to: "/call-for-abstracts" },
        // { label: "Speakers", to: "/speakers" },
      ],
    },
    // {
    //   label: "Attending",
    //   to: "",
    //   dropdown: true,
    //   items: [
    //     { label: "Registration", to: "/registration" },
    //     { label: "Social Functions", to: "/social-functions" },
    //     { label: "Accommodation", to: "/accommodation" },
    //     { label: "Terms and Conditions", to: "/terms-and-conditions" },
    //   ],
    // },
    {
      label: "Sponsorship",
      to: "",
      dropdown: true,
      items: [
        { label: "Sponsorship Oppoprtunities", to: "/opportunities" },
        // { label: "Confirmed Sponsors", to: "/confirmed-sponsors" },
      ],
    },
    {
      label: "Contact Us",
      to: "/contact",
      dropdown: false,
    },
  ],

  // footer menu items
  footer: [
    {
      label: "About",
      items: [
        { label: "Host", to: "/host" },
        { label: "Sponsorship & Exhibition Oppoprtunities", to: "/opportunities" },
      ],
    },
    {
      label: "The Symposium",
      items: [
        { label: "Registration", to: "/registration" },
        { label: "Program Overview", to: "/program-overview" },
        { label: "Call for Abstracts", to: "/call-for-abstracts" },
      ],
    },
    {
      label: "Destination",
      items: [
        { label: "Mackay", to: "/mackay" },
        { label: "Venue", to: "/venue/#mackay" },
      ],
    },
    {
      label: "Contact",
      items: [
        { label: "Contact Us", to: "/contact" },
        { label: "Privacy Policy", to: "/privacy-policy" },
      ],
    },
  ],

  // social menu items
  social: [
    {
      name: "facebook",
      url: "#",
      screenReader: "Follow us on Facebook",
    },
    {
      name: "linkedin",
      url: "#",
      screenReader: "Follow us on LinkedIn",
    },
    {
      name: "instagram",
      url: "#",
      screenReader: "Follow us on Instagram",
    },
    {
      name: "twitter",
      url: "#",
      screenReader: "Follow us on Twitter",
    },
  ]
};
